import { Injectable, afterNextRender, computed, inject, signal } from '@angular/core';
import { DropdownItemBase } from 'configs/global';
import { SubscriptionGear } from 'frontend-state/src/people';
import { informationOfAIModels } from 'generated/ai-models-info.generated';

@Injectable({ providedIn: 'root' })
export class ModelGear {
    defaultModelId = informationOfAIModels[0].model_id;
    currentModelProductId = signal<string | null>(null);
    subscriptionGear = inject(SubscriptionGear);
    informationOfAIModels = informationOfAIModels
    aiModelInformation = computed(() => {
        const model = informationOfAIModels
            .find(m => m.model_id === this.currentModelProductId()) ?? null;
        return model;
    })

    modelList = computed<Record<string, DropdownItemBase[] | undefined>>(() => {
        const list = this.informationOfAIModels.map(m => ({
            id: m.model_id,
            title: m.model_display_name,
            // description: ` ${m.model_price}đ/token. ${m.model_description}`,
            description: `${m.model_description}`,
            image: m.model_image,
            // description: ` ${Math.round(m.model_price * 1024)}đ/tin. ${m.model_description}`,
            group_id: m.model_rank,
            group: {
                title: this.rankToString(m.model_rank),
                description: this.rankToString(m.model_rank),
                groupIndex: m.model_rank,
            },
            disabled: this.subscriptionGear.mySubscription()?.subscriptionType === 'free' && m.model_rank > 2
        } as DropdownItemBase));

        const groupItems = Object.groupBy(list, item => item.group_id!);
        return groupItems;
    });

    constructor() {
        afterNextRender(() => { this.recoveryPreviousModel(); })
    }


    rankToString(model_rank: number) {
        switch (model_rank) {
            case 1:
                return 'sơ cấp'
            case 2:
                return 'trung cấp'
            case 3:
                return 'thượng cấp'
            default:
                return '';
        }
    }

    autoSaveModelIdSelection() {
        const modelProductId = this.currentModelProductId();
        if (modelProductId) {
            console.log('model changes', modelProductId);
            localStorage.setItem('modelProductId', modelProductId);
        }
    }

    recoveryPreviousModel() {
        const modelProductIdPreviously = localStorage.getItem('modelProductId');
        if (modelProductIdPreviously?.length) {
            const modelInDatabase = informationOfAIModels.find(m => m.model_id === modelProductIdPreviously);
            if (modelInDatabase) {
                this.currentModelProductId.set(modelInDatabase.model_id);
            } else {
                this.currentModelProductId.set(this.defaultModelId);
                localStorage.removeItem('modelProductId');
            }
        } else {
            this.currentModelProductId.set(this.defaultModelId);
        }
    }

}