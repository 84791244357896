import {
  afterNextRender,
  inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';

import { DialogService } from '@ngneat/dialog';
import { ToastService } from 'frontend-core/src/services/toast.service.js';
import { ConfirmComponent } from './confirm/confirm.component.js';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { graphqlErrorShowOnSnackbarServiceForClientUser$ } from 'frontend-core/src/services/use-graphql.helper.js';
import { isBrowser } from 'frontend-core/src/services/universal.service.js';

export function checkPlatformSSR() {
  const platformId = inject(PLATFORM_ID);

  if (isPlatformBrowser(platformId)) {
    // runs on client / browser
    console.log('Output is generated only in browser not in server.');
  }
  if (isPlatformServer(platformId)) {
    // runs on server / node
    console.log('Output is generated only in server not in browser.');
  }
}
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  toast = inject(ToastService);
  dialogService = inject(DialogService);
  isServer = !isBrowser();
  constructor() {
    afterNextRender(() => {
      // console.log('after')
      graphqlErrorShowOnSnackbarServiceForClientUser$.subscribe((message) => {
        console.log('after xx');
        this.showError(message);
      });
    });
  }

  openConfirm(question = 'Bạn có chắc?', yes = 'Có', no = 'Không') {
    return this.dialogService.open(ConfirmComponent, {
      data: { question, yes, no },
      id: Math.random().toString(36).substring(2, 9),
    });
  }

  showSuccess(
    message: string = 'thành công!',
    customOption = { duration: 2000 },
  ) {
    if (this.isServer) {
      return console.error(`server success:` + message);
    }
    return this.toast.showToast(message, 'success', customOption);
  }

  showError(message: string = 'thất bại!', customOption = { duration: 5000 }) {
    if (this.isServer) {
      return console.error(`server error:` + message);
    }
    console.warn('show error')
    return this.toast.showToast(message, 'error', customOption);
  }

  showWarning(message: string, customOption = { duration: 3000 }) {
    if (this.isServer) {
      return console.error(`server warning:` + message);
    }
    return this.toast.showToast(message, 'warning', customOption);
  }
}
