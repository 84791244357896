import { Injectable, computed, inject, signal } from '@angular/core';
import { DialogService } from '@ngneat/dialog';
import { PeopleGear } from './people.gear';
import { DialogsPeople, EndTrialDialog, ManageSubscriptionDialog, WelcomeTrialDialog } from '@app/frontend-ui';
import { SnackbarService, graphQLClient } from '@app/frontend-core';
import { injectQuery, injectMutation } from '@tanstack/angular-query-experimental';
import { AppProductSubscriptionPaymentStatus, MySubscriptionDocument, MySubscriptionQuery } from '@app/generated';
import { DepositPaymentBody } from 'configs/global';


@Injectable({ providedIn: 'root' })
export class SubscriptionGear {
    peopleGear = inject(PeopleGear);
    dialogsPeople = inject(DialogsPeople);
    dialogService = inject(DialogService);
    snackbarService = inject(SnackbarService);
    activeSubscriptionId = signal('');

    mySubscriptionQuery = injectQuery(() => ({
        queryKey: ['mySubscription'],
        queryFn: async ({ queryKey }) => {
            const { appProductMySubscription } = await graphQLClient.request(
                MySubscriptionDocument,
            );
            // this.checkSubscription(result);
            // const sub = null;
            const sub = appProductMySubscription?.nodes[0];
            if (sub) {
                this.mySubscription.set(sub);
                this.activeSubscriptionId.set(sub.subscriptionId!);
                return sub;
            } else {
                return this.claimFree();
            }
        },
        enabled: !!this.peopleGear.myProfile()?.userId,
    }));

    async claimFree() {
        if (this.peopleGear.myProfile()?.userId) {
            const response = await fetch('/v1/api/claim-free', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });

            this.snackbarService.showSuccess("Đã kích hoạt gói miễn phí")

            setTimeout(() => {
                this.mySubscriptionQuery.refetch();
            }, 1000);
            return response.json();
        } else {
            return null;
        }

    }

    mySubscription = signal<NonNullable<AppProductSubscriptionPaymentStatus> | null>(null);


    depositStatus = injectMutation(() => ({
        mutationFn: async ({
            deposit_code,
            product_quantity,
            product_unit_price,
            deposit_amount,
        }: DepositPaymentBody) => {
            const response = await fetch('/v1/api/deposit-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    deposit_code,
                    product_quantity,
                    product_unit_price,
                    deposit_amount,
                }),
            });
            return response.json();
        },
        networkMode: 'online',
    }));

    orderEstimate = injectMutation(() => ({
        mutationFn: async ({
            deposit_code,
            product_quantity,
            product_unit_price,
            deposit_amount,
        }: DepositPaymentBody) => {
            const response = await fetch('/v1/api/order-estimate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    deposit_code,
                    product_quantity,
                    product_unit_price,
                    deposit_amount,
                }),
            });
            return response.json() as Promise<{ draft_order: any, order_payload: any, valid_discounts: any[] }>;
        },
    }));



    openSubscriptionDialog(activeSubscriptionId?: string) {
        const dialogRef = this.dialogService.open(ManageSubscriptionDialog);
        dialogRef.afterClosed$.subscribe((res: any) => {
            const newActive = res && res.activeSubscriptionId ? res.activeSubscriptionId : activeSubscriptionId;
            if (newActive) this.activeSubscriptionId.set(newActive)
        })

    }

    async activateTrial() {
        this.openWelcomeTrialDialog();

    }

    openWelcomeTrialDialog() {
        const dialog = this.dialogService.open(WelcomeTrialDialog);
    }
    openEndTrialDialog() {
        const dialog = this.dialogService.open(EndTrialDialog);
    }


}