import { OnInit, inject, input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import dayjs from 'dayjs';
import { SubscriptionGear } from '@app/frontend-state';


@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'app-package-information',
    template: `
    <div class="col-span-1 py-1 md:py-2 text-xs md:text-sm">
        <h3 class="font-bold py-1">
            @if(monthInputValue() && monthlyPrice()) {
                Gói mới
            } @else {
                Hiện tại: {{ susbscriptionGear.mySubscription()?.subscriptionType === 'paid' ? 'Trả phí' : 'Miễn phí' }}
             }
        </h3>

        @if(visionDetail()) {
            @if (monthInputValue() && monthlyPrice() ? getNewPackage(monthInputValue(),monthlyPrice()) : getCurrentPackage(); as pack) {
            <p class="py-0.5"> Số tiền hàng tháng: {{ pack.monthlyPrice | currency: 'VND' }} </p>
            <p class="py-0.5"> Ngày bắt đầu: {{ pack.beginDate | date: 'dd/MM/yyyy' }} </p>
            <p class="py-0.5"> Ngày hết hạn: {{ pack.endDate | date: 'dd/MM/yyyy' }}
                <!-- <span> ({{ pack.remainMonth }} tháng)</span> -->
            </p>
        }

        }
    </div>
    `,
})

export class PackageInformationComponent {
    visionDetail = input(true);
    monthInputValue = input(0);
    monthlyPrice = input(0);

    susbscriptionGear = inject(SubscriptionGear);

    getPackageName(subscriptionType?: string) {
        switch (subscriptionType) {
            case 'paid':
                return 'Trả phí'
            case 'trial':
                return 'Dùng thử'
            case 'free':
                return 'Miễn phí'
            default:
                return '...';
        }
    }

    getCurrentPackage() {
        const sub = this.susbscriptionGear.mySubscription();
        const currentDeposit = +sub?.subscriptionBalance;
        const monthlyPrice = +(sub?.subscriptionMonthlyValue || 0);

        const remainDeposit = currentDeposit;
        const subscriptionActivateDate = sub?.subscriptionPaymentDate;

        const totalMonth = +(remainDeposit / monthlyPrice).toFixed(0);
        const remainMonthInNumber = totalMonth + 1;
        const endOfMonth = dayjs(subscriptionActivateDate).add(remainMonthInNumber, 'month').toDate();

        return {
            monthlyPrice,
            beginDate: subscriptionActivateDate,
            remainMonth: remainMonthInNumber,
            endDate: endOfMonth,
        };
    }

    getNewPackage(monthInputValue: number, monthlyPrice: number) {
        const sub = this.susbscriptionGear.mySubscription();
        const currentDeposit = +sub?.walletBalance;

        const newDeposit = monthlyPrice * monthInputValue;
        const remainDeposit = currentDeposit + newDeposit - monthlyPrice;
        const subscriptionActivateDate = new Date();

        const totalMonth = +(remainDeposit / monthlyPrice).toFixed(0);
        const remainMonthInNumber = totalMonth + 1;
        const endOfMonth = dayjs(subscriptionActivateDate).add(remainMonthInNumber, 'month').toDate();

        return {
            monthlyPrice,
            beginDate: subscriptionActivateDate,
            remainMonth: remainMonthInNumber,
            endDate: endOfMonth,
        };
    }
}
