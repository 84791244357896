export interface AIModelInformation {
    model_id: string;
    // model_provider: string;
    // model_id_of_provider: string;
    model_username: string;
    model_display_name: string;
    model_family: string;
    model_status: string;
    model_type: string;
    model_description: string;
    model_image: string | null;
    model_rank: number;
    model_price: number;
    // model_cost: number;
    model_stop?: string[];
}


export const informationOfAIModels: AIModelInformation[] = [
    {
        "model_id": "gpt-4o-mini",
        "model_username": "gpt-4o-mini",
        "model_display_name": "GPT-4o mini",
        "model_family": "gpt",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 1,
        "model_description": "OpenAI, tác vụ dễ, nhanh và rẻ",
        "model_image": "/frontend-assets/images/model-family/openAI.jpeg",
        "model_price": 0.0156
    },
    {
        "model_id": "claude-3-5-haiku-latest",
        "model_username": "claude-3-5-haiku-latest",
        "model_display_name": "Claude-haiku 3.5",
        "model_family": "claude",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 1,
        "model_description": "Anthorpic, tác vụ dễ, viết sáng tạo tốt ",
        "model_image": "/frontend-assets/images/model-family/claude.jpeg",
        "model_price": 0.0156
    },
    {
        "model_id": "gemini-1.5-flash",
        "model_username": "gemini-1.5-flash",
        "model_display_name": "Gemini-1.5 flash",
        "model_family": "gpt",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 1,
        "model_description": "Google, tác vụ dễ, tốt cho dịch thuật",
        "model_image": "/frontend-assets/images/model-family/gemini.jpeg",
        "model_price": 0.0156
    },
    {
        "model_id": "gpt-4o",
        "model_username": "gpt-4o",
        "model_display_name": "GPT-4o",
        "model_family": "gpt",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 2,
        "model_description": "OpenAI, công việc phức tạp, logic tính toán",
        "model_image": "/frontend-assets/images/model-family/openAI.jpeg",
        "model_price": 0.468
    },
    {
        "model_id": "claude-3-5-sonnet-latest",
        "model_username": "claude-3-5-sonnet-latest",
        "model_display_name": "Claude-Sonet-3.5 new",
        "model_family": "claude",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 2,
        "model_description": "Anthorpic, công việc phức tạp, logic tính toán, lập trình",
        "model_image": "/frontend-assets/images/model-family/claude-level-3.jpeg",
        "model_price": 0.702
    },
    {
        "model_id": "gemini-1.5-pro",
        "model_username": "gemini-15-pro",
        "model_display_name": "Gemini-1.5 pro",
        "model_family": "gpt",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 2,
        "model_description": "Google, công việc phức tạp, logic tính toán, dịch thuật",
        "model_image": "/frontend-assets/images/model-family/gemini.jpeg",
        "model_price": 0.39
    }
];