import { Injectable, signal, computed, inject } from "@angular/core";
import { ObjectContent, ObjectTab, TreeNode } from "configs/global";
import { Subject } from "rxjs";
import { SnackbarService } from "@app/frontend-core";
import { injectMutation } from "@tanstack/angular-query-experimental";


@Injectable({ providedIn: 'root' })
export class TabManagerService<T = any> {

    snackbarService = inject(SnackbarService);
    doubleClickTimeout: any = null;
    tabContentCacheData = signal<Record<string, ObjectContent>>({});
    objectTabs = signal<ObjectTab<T>[]>([]);
    activeTab = computed(() => this.objectTabs().find(tab => tab.active));
    openFileEvent = new Subject<ObjectContent>();

    toggleActiveTab(objectId: string): void {
        this.objectTabs.update(tabs => tabs.map(tab => ({ ...tab, active: tab.objectId === objectId })));
        this.emitDataToEditor('updateTab', objectId);
    }

    updateTab(updateTab: Partial<ObjectTab<T>> & { objectId: string }): void {
        this.objectTabs.update(tabs => tabs.map(tab => {
            return {
                ...tab,
                ...(tab.objectId === updateTab.objectId ? updateTab : {}),
            };
        }));

    }

    markTabDirty({ objectId, dirty }: { objectId?: string, dirty: boolean }) {
        objectId = objectId ?? this.activeTab()!.objectId;
        if (dirty) {
            this.updateTab({
                objectId: objectId,
                dirty: true,
                touched: true,
            });
        } else {
            this.updateTab({
                objectId: objectId,
                dirty: false,
            });
        }
    }

    addTab(options: Partial<ObjectTab<T>> & { objectId: string }): void {
        this.objectTabs.update(fileTabs => {
            const newTab: ObjectTab<T> = {
                objectId: options.objectId,
                name: options.name ?? '',
                touched: options.touched ?? false,
                active: true,
                dirty: false,
            };

            this.saveTabContentCache(newTab.objectId, { name: newTab.name });
            
            fileTabs = [...fileTabs.map(tab => ({ ...tab, active: false })), newTab];
            console.log('after update', fileTabs);
            this.emitDataToEditor('new-tab', options.objectId);
            return fileTabs;
        });
    }

    removeTab(objectId: string): void {
        this.objectTabs.update(fileTabs => {
            const currentTabIndex = fileTabs.findIndex(tab => tab.objectId === objectId);
            if (currentTabIndex >= 0) {
                const restOfTabs = fileTabs.filter(tab => tab.objectId !== objectId);
                return restOfTabs;
            }
            return fileTabs;
        });
    }

    saveTabContentCache(objectId: string, objectContent: Partial<ObjectContent>): void {
        this.tabContentCacheData.update(contentData => {
            contentData[objectId] = { ...contentData[objectId], ...objectContent }
            return contentData;
        });
    }

    hasTabContent(objectId: string): boolean {
        return !!this.tabContentCacheData()[objectId];
    }

    getTabContent(objectId: string): ObjectContent | undefined {
        return this.tabContentCacheData()[objectId];
    }

    emitDataToEditor(sourceAction: string, objectId: string): void {
        const targetObject = this.tabContentCacheData()[objectId];
        this.openFileEvent.next(targetObject);
    }

    getActiveTab(){
        return this.objectTabs().find(tab => tab.active);
    }

    getTabIndex(objectId = this.activeTab()?.objectId) {
        return this.objectTabs().findIndex(tab => tab.objectId === objectId);
    }

    tabExists(objectId: string): boolean {
        return this.objectTabs().some(tab => tab.objectId === objectId);
    }

    getAllTabIds(): string[] {
        return this.objectTabs().map(tab => tab.objectId);
    }

    openFileData(treeNode: TreeNode, touched = false) {
        try {
            const { objectId, name } = treeNode;
            const activeTab = this.activeTab();
            const freshTab = this.objectTabs().find(tab => !tab.touched);
            if (!freshTab) {
                console.warn('openFileData add new tab', treeNode);
                this.addTab({ objectId, name: name, touched });
            } else {
                console.warn('openFileData replace tab', treeNode);
                activeTab && this.removeTab(freshTab.objectId);
                this.addTab({ objectId, name: name, touched });
            }
            this.readObjectData.mutate({
                objectId: treeNode.objectId,
                projectId: treeNode.projectId,
            });
        } catch (error) {
            console.error('Error reading file content', treeNode.name, error);
        }
    }


    openFile(treeNode: TreeNode) {
        const tabExist = this.objectTabs().find(tab => tab.objectId === treeNode?.objectId);
        if (this.doubleClickTimeout) {
            // Double click detected
            clearTimeout(this.doubleClickTimeout);
            this.doubleClickTimeout = null;
            console.log('is double click, openFileData');

            if (tabExist) {
                const activeTabId = this.activeTab()?.objectId;
                if (activeTabId) {
                    this.updateTab({ objectId: treeNode.objectId, active: false });
                }
                this.updateTab({ objectId: treeNode.objectId, active: true, touched: true });
            } else {
                this.openFileData(treeNode, true);
            }
        } else {
            // Set timeout for potential double click
            this.doubleClickTimeout = setTimeout(() => {
                // Single click action
                this.doubleClickTimeout = null;
                console.log('is single click, openFileData', treeNode);

                if (tabExist) {
                    this.toggleActiveTab(treeNode?.objectId);
                } else {
                    this.openFileData(treeNode);
                }
            }, 300);
        }
    }


    getSignUrl = async (projectId: string, key: string) => {
        const response = await fetch(`/v1/api/storage/url?projectId=${projectId}&key=${key}`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        const url = data.url;
        return url;
    }

    getPublishContent = async (projectId: string, objectId: string) => {
        const response = await fetch(`/v1/api/storage/publish?projectId=${projectId}&objectId=${objectId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            try {
                const errorData = await response.json();
                console.warn('response', response, errorData);
                this.snackbarService.showError(errorData.message);
            } catch (error) {
                this.snackbarService.showError(`HTTP error! status: ${response.status}`);
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            const blob = await response.blob();
            const buffer = await blob.arrayBuffer();
            const content = new TextDecoder('utf-8').decode(buffer);
            return JSON.parse(content) as ObjectContent;
        }
    }

    getObjectContent = async (projectId: string, objectId: string) => {
        const response = await fetch(`/v1/api/storage/file?projectId=${projectId}&objectId=${objectId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            try {
                const errorData = await response.json();
                console.warn('response', response, errorData);
                this.snackbarService.showError(errorData.message);
            } catch (error) {
                this.snackbarService.showError(`HTTP error! status: ${response.status}`);
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            const blob = await response.blob();
            const buffer = await blob.arrayBuffer();
            const content = new TextDecoder('utf-8').decode(buffer);
            return JSON.parse(content) as ObjectContent;
        }
    }

    readObjectData = injectMutation(() => ({
        mutationKey: ['readObjectData'],
        mutationFn: async ({ objectId, projectId }: { objectId: string, projectId: string }) => {
            // const url = await this.getSignUrl(projectId, key);
            // const fileResponse = await fetch(url);

            const payload = await this.getObjectContent(projectId, objectId);

            // await new Promise(resolve => setTimeout(resolve, 5000));
            // const content = '';
            this.saveTabContentCache(objectId, payload);
            this.emitDataToEditor('readObjectData', objectId);
            return payload;
        }
    }));

}