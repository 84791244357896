
export const jsonToBase64 = (jsonObject: any): string => {
    const jsonString = JSON.stringify(jsonObject);
    return btoa(encodeURIComponent(jsonString));
};

export const base64ToJson = (base64String: string): any => {
    if (!base64String) {
        console.warn('base64String is empty or null');
        return null;
    }
    try {
        // Remove the MIME type prefix if present
        const base64Data = base64String.split(',')[1] || base64String;

        // Decode the base64 string
        const decodedString = atob(base64Data);

        // Parse the decoded string as JSON
        const jsonString = decodeURIComponent(decodedString);
        const jsonData = JSON.parse(jsonString);
        console.log('your jsonData', jsonData);
        return jsonData;
    } catch (error) {
        console.log('base64String', base64String);
        console.error('Error decoding base64 string:', error);
        return null;
    }
};

// const blobToBase64 = async (blob: Blob) => {
//     return new Promise<string>((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onload = () => {
//             const dataUrl = reader.result as string;
//             const base64 = dataUrl.split(',')[1];
//             resolve(base64);
//         };
//         reader.onerror = () => reject(reader.error);
//         reader.readAsDataURL(blob);
//     });
// }


export const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};