import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastService } from 'frontend-core/src/services/toast.service';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  template: `
<div *ngIf="showToast && toastMessage" class="fixed z-1060"
[ngClass]="{
    'bottom-5 right-5': toastConfig.position === 'bottom-right',
    'top-5 right-5': toastConfig.position === 'top-right'
  }">
  <div class="w-80 shadow rounded bg-base-300 text-base-content">
    <div
      *ngIf="toastConfig.isBtnClose"
      (click)="closeToast()"
      class="absolute top-2 right-2"
    >
      <!-- <fa-icon [icon]="icons.faXmark"></fa-icon> -->
      x
    </div>
    <div class="py-4 px-6 flex items-center">
      <div [ngSwitch]="toastConfig.typeToast" class="mr-2">
        <p *ngSwitchCase="'success'">
          ✅
        </p>
        <p *ngSwitchCase="'warning'">
         ⚠️
        </p>
        <p *ngSwitchDefault>
          🚫
        </p>
      </div>
      <p class="text-base">{{ toastMessage }}</p>
    </div>
  </div>
</div>

  `,
  styles: [
    `
      :host .z-1060 {
        z-index: 1060;
      }
    `,
  ],
})
export class ToastComponent implements OnInit {
  showToast = false;
  toastMessage = '';

  toastConfig = {
    isBtnClose: false,
    autoClose: true,
    duration: 2000,
    typeToast: 'success',
    position: 'top-right',
  };
  cd = inject(ChangeDetectorRef);
  toastService = inject(ToastService);


  ngOnInit() {
    this.showToast = true;
    this.toastService.status.subscribe(
      (data: { message: string; typeToast: string; toastConfig: any }) => {
        if (data?.message) {
          this.showToast = true;
          this.toastConfig = {
            ...this.toastConfig,
            typeToast: data.typeToast,
            ...data.toastConfig,
          };
          this.toastMessage = data.message;
          this.cd.detectChanges();

          setTimeout(() => {
            this.showToast = false;
            this.cd.detectChanges();
          }, data.toastConfig?.duration ?? this.toastConfig.duration);
        }
      },
    );
  }

  closeToast() {
    this.showToast = false;
  }
}
