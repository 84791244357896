<h2 class="font-bold text-lg md:text-xl py-4 px-2">
  quản lý đăng ký
</h2>

<hr class="py-0">

<div class="overflow-x-auto px-2 pb-2">
  <div class="grid grid-cols-2 text-xs md:text-sm">
    @if(subscriptionGear.mySubscription(); as sub) {
    <app-package-information [visionDetail]="subscriptionGear.mySubscription()?.subscriptionType === 'paid'"></app-package-information>

    <div class="col-span-2 py-0.5">
      @switch (subscriptionGear.mySubscription()?.subscriptionType) {
      @case('free') {
      <p>Hồi phục về: {{ sub.subscriptionDailyValue| currency: 'VND' }} mỗi ngày vào lúc 7:00 </p>
      <p>Bấm nút thanh toán để nâng cấp gói trả phí </p>
      }
      @case('paid') {
      <p>Bấm nút thanh toán để gia hạn hoặc thay đổi mức tiền của gói trả phí </p>
      }
     
      @default {
        <p>Không biết </p>
      }
      }
    </div>
    }
  </div>
</div>

<hr class="py-1">


<div class="flex items-center justify-evenly px-0 md:px-2 py-2 md:py-4">
  <button class="btn btn-sm w-max py-2 px-2" [routerLink]="'/deposit'" (click)="dialogRef.close()">Thanh toán</button>
  <button class="btn btn-sm w-max py-2 px-2" (click)="dialogRef.close()">Đóng</button>
</div>