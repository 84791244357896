import { Routes } from '@angular/router';


export const buyerSharedRouting: Routes = [
 
  {
    path: '',
    loadComponent: () =>
      import('../lib/buyer-shared.layout.js').then(
        ({ buyerWebSharedLayout }) => buyerWebSharedLayout,
      ),
    children: [
      // {
      //   path: '',
      //   loadComponent: () =>
      //     import('./landing/landing.page.js').then(
      //       ({ LandingPage }) => LandingPage,
      //     ),
      // },
      {
        path: 'changelog',
        loadComponent: () =>
          import('./changelog/changelog.page.js').then(
            ({ ChangelogPage }) => ChangelogPage,
          ),
      },
      {
        path: 'roadmap',
        loadComponent: () =>
          import('./roadmap/roadmap.page.js').then(
            ({ RoadmapPage }) => RoadmapPage,
          ),
      },
      {
        path: 'pricing',
        loadComponent: () =>
          import('./pricing/pricing.page.js').then(
            ({ PricingPage }) => PricingPage,
          ),
      },
      {
        path: 'deposit',
        loadComponent: () =>
          import('./deposit/deposit.page.js').then(
            ({ DepositPage }) => DepositPage,
          ),
      },
      {
        path: 'blog',
        title: 'Blog của Losa',
        loadComponent: () =>
          import('./blogs/blogs.page.js').then(
            ({ BlogsPage }) => BlogsPage,
          ),
        data: {
          reuseComponent: true,
        },
      },
      {
        path: 'blog/:article-slug-href',
        loadComponent: () =>
          import('./blog-read/blog-read.page.js').then(
            ({ BlogReadPage }) => BlogReadPage,
          ),
        data: {
          reuseComponent: true,
        },
      },
    ],
  },
];
