export function kernelServices(): string {
  return 'kernel-services';
}

// ----------------------------

/**
 * Toggles an element's open/closed state and manages click events.
 * @param event The initial click event that triggers the function.
 * @param options Configuration options for the toggle behavior.
 */
export function toggleElementOnClick(event: Event, options: ToggleOptions = {}): void {
  const {
    toggleFn,
    blurOnClose = false,
    logLevel = 'none'
  } = options;

  const targetElement = event.target as HTMLElement;
  const isCurrentlyOpen = targetElement.hasAttribute('data-open');

  try {
    if (isCurrentlyOpen) {
      closeElement(targetElement, { toggleFn, blurOnClose, logLevel });
    } else {
      openElement(targetElement, { toggleFn, logLevel });
    }
  } catch (error) {
    handleError(error, logLevel);
  }
}

interface ToggleOptions {
  toggleFn?: () => any;
  blurOnClose?: boolean;
  logLevel?: 'none' | 'warn' | 'error' | 'info';
}

function openElement(element: HTMLElement, options: ToggleOptions): void {
  const { toggleFn, logLevel } = options;
  element.setAttribute('data-open', 'true');
  toggleFn?.();
  log('Element opened', logLevel, 'info');

  const controller = new AbortController();
  document.addEventListener('click', (e) => handleOutsideClick(e, element, options, controller),
    { signal: controller.signal, capture: true }
  );
}

function closeElement(element: HTMLElement, options: ToggleOptions): void {
  const { toggleFn, blurOnClose, logLevel } = options;
  element.removeAttribute('data-open');
  toggleFn?.();
  if (blurOnClose) {
    (document.activeElement as HTMLElement)?.blur();
  }
  log('Element closed', logLevel, 'info');
}

function handleOutsideClick(
  event: MouseEvent,
  openElement: HTMLElement,
  options: ToggleOptions,
  controller: AbortController
): void {
  const clickTarget = event.target as HTMLElement;
  const isClickInside = openElement.contains(clickTarget);

  if (!isClickInside) {
    closeElement(openElement, options);
    controller.abort();
  } else {
    event.preventDefault();
    event.stopPropagation();
  }
}

function handleError(error: unknown, logLevel: ToggleOptions['logLevel']): void {
  log(`Error in toggleElementOnClick: ${error}`, logLevel, 'error');
}

function log(message: string, logLevel: ToggleOptions['logLevel'], type: 'warn' | 'error' | 'info'): void {
  if (logLevel === 'none' || (logLevel === 'error' && type !== 'error')) return;
  console[type](message);
}
// ----------------------------

export function clickInsideBlur() {
  (document.activeElement as HTMLElement).blur();
}

export function isMobile() {
  try {
    const userAgent = navigator.userAgent;
    // console.log('userAgent', userAgent, /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
    //   /\b(Android|Windows Phone|iPhone|iPad|iPod)\b/i.test(userAgent)
    // )
    if ('maxTouchPoints' in navigator) return navigator.maxTouchPoints > 0;
    const mQ = matchMedia?.('(pointer:coarse)');
    if (mQ?.media === '(pointer:coarse)') return !!mQ.matches;
    if ('orientation' in window) return true;
    return (
      /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
      /\b(Android|Windows Phone|iPhone|iPad|iPod)\b/i.test(userAgent)
    );
  } catch (error) {
    return false;
  }
}

export async function copyText(text: string, htmlContent?: string) {
  const fallbackCopyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      console.log('Text copied to clipboard using fallback method');
    } catch (err) {
      console.error('Failed to copy text with fallback method: ', err);
    }
    document.body.removeChild(textArea);
  };

  if (navigator.clipboard) {
    if (htmlContent) {
      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
            'text/plain': new Blob([text], { type: 'text/plain' }),
          }),
        ]);
        console.log('HTML and text copied to clipboard');
      } catch (err) {
        console.error('Failed to copy HTML content: ', err);
        console.warn('Fallback to plain text copy');
        fallbackCopyToClipboard(text);
      }
    } else {
      try {
        await navigator.clipboard.writeText(text);
        console.log('Text copied to clipboard');
      } catch (err) {
        console.error('Failed to copy text: ', err);
        fallbackCopyToClipboard(text);
      }
    }
  } else {
    console.warn('Clipboard API not supported, falling back to plain text copy');
    fallbackCopyToClipboard(text);
  }
}

// https://github.com/saadeghi/daisyui/issues/824
// export function clickOutsideListener(focusArea: HTMLElement) {
//   document.addEventListener(
//     'click',
//     (fakeEvent) => {
//       const controller = new AbortController();
//       document.addEventListener(
//         'click',
//         (realEvent) => {
//           const realTarget = realEvent.target as HTMLElement;
//           const clickInside = focusArea.contains(realTarget);
//           const fakeTarget = realEvent.target as HTMLElement;
//           // console.log('realTarget', fakeEvent, realEvent, realTarget);
//           console.log('click inside?', clickInside);
//           if (!clickInside) {
//             realEvent.preventDefault();
//             realEvent.stopPropagation();
//           } else {
//             (document.activeElement as HTMLElement).blur();
//           }
//           controller.abort();
//         },
//         { signal: controller.signal, capture: true },
//       );
//     },
//     { once: true },
//   );
// }