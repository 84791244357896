import { Component, inject, computed, input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarState } from './navbar.state';
import { LucideAngularModule, Menu } from 'lucide-angular';
import { CollapseMenu, CollapseMenusComponent } from '../components/collapse-menu.component';
import { MainSidebarComponent } from './main/main-sidebar.component';

@Component({
  selector: 'app-side-drawer',
  standalone: true,
  imports: [CommonModule, LucideAngularModule, CollapseMenusComponent, MainSidebarComponent],
  styles: [`
   :host {
    display: flex;
    width: 100%;
    height: 100%;
   }
  `],
  template: `
 <div class="drawer h-full" [ngClass]="navbarState.chatLeftBarsMenu() ? 'md:drawer-open' : ''">
  <input title="drawer" id="left-chat-drawer" type="checkbox" class="drawer-toggle"
    [attr.checked]="navbarState.chatLeftBarsMenu() ? '' : null" />

  <div class="drawer-content px-1 flex flex-col">
  <div class="sticky w-full top-0 left-0 z-10">
     <ng-container [ngTemplateOutlet]="navbarTemplate() ?? defaultNavbarTemplate"></ng-container>
    </div>
    <div class="relative flex-1 flex flex-col justify-center items-center mx-auto w-full max-w-full md:max-w-screen-md lg:max-w-screen-lg">
      <ng-content></ng-content>
    </div>

  </div>
  <div class="drawer-side z-10">
    <label (click)="navbarState.chatLeftBarsMenu.set(false)" aria-label="close sidebar" class="drawer-overlay"></label>
    <div class="flex flex-col bg-base-300 h-full w-[85vw] md:w-44 lg:w-52 max-h-dvh relative">
      <app-collapse-menus class="flex-grow min-w-0 overflow-auto" [collapseMenus]="collapseMenus()"></app-collapse-menus>
      <app-main-sidebar class="h-auto" [attr.data-name]="'bottomSidebar'" [dataMenus]="navbarState.bottomSidebar()"></app-main-sidebar>
    </div>
  </div>
</div>

<ng-template #defaultNavbarTemplate>
 <div class="navbar p-0 min-h-8 w-full bg-base-100">
  <div class="navbar-start flex items-start">
    <button (click)="navbarState.toggleLeftBarsMenu()" class="btn text-xl btn-ghost">
      <lucide-icon [name]="icons.Menu" class="w-6 h-6"></lucide-icon>
    </button>
  </div>
</div>

</ng-template>
  `,

})
export class SideDrawerComponent {
  icons = { Menu }
  navbarState = inject(NavbarState);
  navbarTemplate = input<TemplateRef<any>>();

  collapseMenus = input<CollapseMenu[]>([
    {
      id: 'main-sidebar',
      name: '',
      opened: true,
      component: MainSidebarComponent,
      actions: [],
    },
  ]);
}