import { Injectable, computed, inject, signal } from '@angular/core';
import {
  DATA_NAVIGATION_MENU,
  MAIN_SIDEBAR,
  DATA_MODIFER_MENU,
  DataMenuInterface,
  BOTTOM_SIDEBAR,
} from 'configs/src/navigation/routing-config';
import { Router } from '@angular/router';
import { isMobile, isBrowser } from '@app/frontend-core';
import { fromEvent, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavbarState {
  chatLeftBarsMenu = signal<boolean>(false);

  dataNavigationMenus = signal<DataMenuInterface[]>(DATA_NAVIGATION_MENU);
  mainSidebar = signal<DataMenuInterface[]>(MAIN_SIDEBAR);
  bottomSidebar = signal<DataMenuInterface[]>(BOTTOM_SIDEBAR);
  dataModiferMenus = signal<DataMenuInterface[]>(DATA_MODIFER_MENU);

  chatMenu = computed(() =>
    this.mainSidebar().find((dataMenu) => dataMenu.routerId === 'chat'),
  );
  doubleEditorMenu = computed(() =>
    this.mainSidebar().find(
      (dataMenu) => dataMenu.routerId === 'double-editor',
    ),
  );
  router = inject(Router);

  constructor() {
    if (isBrowser()) {
      fromEvent(document, 'mousemove').pipe(take(1)).subscribe(() => {
        if (!isMobile()) {
          this.chatLeftBarsMenu.set(true);
        }
      });
    }

    
   }
  
  toggleLeftBarsMenu(newValue?: boolean) {
    this.chatLeftBarsMenu.update((value) => {
      // console.log('toggleLeftBarsMenu', newValue, value);
      return newValue ?? !value;
    });
  }

  toggleNavigationMenu(routerId: string) {
    this.dataNavigationMenus.update((dataMenus) => {
      return dataMenus.map((dataMenu) => {
        if (dataMenu.routerId === routerId) {
          dataMenu.isActive = true;
          dataMenu.linkTo && this.router.navigateByUrl(dataMenu.linkTo);
        } else {
          dataMenu.isActive = false;
        }
        return dataMenu;
      });
    });
  }

  toggleDataMenu(
    routerId: string,
    mode: 'open' | 'close' | 'toggle' = 'toggle',
  ) {
    this.mainSidebar.update((dataMenus) => {
      return dataMenus.map((dataMenu) => {
        if (dataMenu.routerId === routerId) {
          let value = !dataMenu.isActive;
          if (mode === 'open') value = true;
          else if (mode === 'close') value = false;
          dataMenu.isActive = value;
        } else {
          dataMenu.isActive = false;
        }
        return dataMenu;
      });
    });
  }

  toggleModiferDataMenu(
    routerId: string,
    mode: 'open' | 'close' | 'toggle' = 'toggle',
  ) {
    this.dataModiferMenus.update((dataMenus) => {
      return dataMenus.map((dataMenu) => {
        if (dataMenu.routerId === routerId) {
          let value = !dataMenu.isActive;
          if (mode === 'open') value = true;
          else if (mode === 'close') value = false;
          dataMenu.isActive = value;
        } else {
          dataMenu.isActive = false;
        }
        return dataMenu;
      });
    });
  }
}
