import { CommonModule } from '@angular/common';
import { Component, input, Type, TemplateRef, Signal } from '@angular/core';
import { ActionEvent } from '@app/frontend-core';
import { ChevronDown, ChevronRight, LucideAngularModule, Plus } from 'lucide-angular';

export interface CollapseMenu {
    id: string;
    name: string;
    opened: boolean;
    templateRef?: Signal<TemplateRef<any> | undefined>;
    templateContext?: any;
    component?: Type<any> | null;
    componentInputs?: Record<string, any>;
    actions: {
        type: string;
        name: string;
        icon: any;
        trigger: () => any;
    }[];
}

@Component({
    standalone: true,
    imports: [CommonModule, LucideAngularModule],
    selector: 'app-collapse-menus',
    template: `
    <ul class=" flex flex-col items-start h-full w-full">
    @for (tabItem of collapseMenus(); track $index) {
    <li tabindex="0" class="min-w-0 w-full max-w-full transition-none animate-none relative collapse rounded-none my-0"
        [ngClass]="[(tabItem.opened ? 'collapse-open min-h-[16%] flex-1' : 'collapse-close h-fit'), ($first && collapseMenus().length > 1 ? 'max-h-fit' : '')]">
        <input class="min-h-fit" title="chọn hội thoại" type="checkbox" (change)="tabItem.opened = !tabItem.opened" />
        <div class="collapse-title bg-base-100 p-0 flex items-center justify-between min-h-8 overflow-hidden">
           <span class="font-bold truncate flex self-center">
                <lucide-icon class="inline-flex" [name]="!tabItem.opened ? icons.ChevronRight : icons.ChevronDown"></lucide-icon>
                {{ tabItem.name }}
            </span>
            <div class="flex">
                @for (menuAction of tabItem.actions; track $index) {
                    <a class="btn btn-ghost btn-xs cursor-pointer hover:bg-primary hover:text-primary-content z-[1]"
                    [title]="menuAction.name"  (click)="menuAction.trigger()">
                        <lucide-icon [name]="menuAction.icon"></lucide-icon>
                    </a>
                }
            </div>
        </div>
        
        <div class="transition-none animate-none relative  px-0 py-0 flex flex-col overflow-y-auto overflow-x-hidden h-full flex-grow">
            @if(tabItem.templateRef?.(); as templateRef) {
                <ng-container [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="tabItem.templateContext"></ng-container>
            }
            @else if(tabItem.component) {
                <ng-container [ngComponentOutlet]="tabItem.component" [ngComponentOutletInputs]="tabItem.componentInputs"></ng-container>
            } @else {
                <pre> warning: no template or component!!! {{ tabItem | json }} </pre>
            }
        </div>
    </li>
    }

</ul>

    `,
})

export class CollapseMenusComponent {
    // {
    //     id: 'conversations',
    //     name: 'Hội thoại',
    //     opened: true,
    //     actions: [{
    //         actionType: 'new-conversation',
    //         actionName: 'mới',
    //         actionIcon: Plus
    // }],
    // templateRef: undefined
    // },
    collapseMenus = input<CollapseMenu[]>([]);
    icons = { ChevronRight, ChevronDown };

    action(event: ActionEvent) {

    }
}