import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  model,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { LucideAngularModule, ExternalLink, User } from 'lucide-angular';
import { NavbarState } from '../navbar.state';
import { DialogService } from '@ngneat/dialog';
import { UserDialog } from '../user.dialog';
import { CharacterGear, ConversationGear, PeopleGear } from '@app/frontend-state';
import { DataMenuInterface } from 'configs/src/navigation/routing-config';
import { isMobile } from '@app/frontend-core';

@Component({
  selector: 'app-main-sidebar',
  standalone: true,
  imports: [CommonModule, LucideAngularModule, RouterLink],
  styles: [
    `:host { display: flex;  }`,
  ],
  template: `
<div [attr.data-position]="sidebarPosition()" class="self-start sticky top-0 h-full w-full flex justify-start bg-transparent"
[ngClass]="(sidebarPosition() === 'vertical') ? 'flex-col' : ''" >
  @if(showLogo()) {
    <a routerLink="/" class="flex justify-center items-center pt-2">
      <img class="w-9" src="/frontend-assets/icon.webp" />
    </a>
  }

  @for (dataMenuItem of dataMenus(); track $index) {
  <button class="flex justify-start items-center text-xs h-auto w-full"
    [ngClass]="[(sidebarPosition() === 'vertical') ? 'px-2 py-2' : 'flex-col py-2', dataMenuItem.color ?? '', dataMenuItem.backgroundColor ?? '']"
  [title]="dataMenuItem.title" (click)="openMenuItem(dataMenuItem)">
    <lucide-icon [name]="dataMenuItem.icon"></lucide-icon>
    <span class="px-1"> {{ dataMenuItem.title }} </span>
  </button>
}
  <!-- <div class="absolute left-0 bottom-2 w-full">
    <button (click)="openUser()" class="flex justify-center items-center text-xs h-auto w-full">
      <lucide-icon [name]="icons.User"></lucide-icon>
       <span class="hidden lg:block px-1"> {{ peopleGear.myProfile()?.displayName }} </span>
    </button>
  </div> -->

</div>`,

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSidebarComponent {
  @Output() action = new EventEmitter();
  icons = { User };
  sidebarPosition = input<'vertical' | 'horizontal'>('vertical');
  showLogo = input(false);
  cd = inject(ChangeDetectorRef);
  navbarState = inject(NavbarState);
  dataMenus = model(this.navbarState.mainSidebar());
  peopleGear = inject(PeopleGear);
  characterGear = inject(CharacterGear);
  router = inject(Router);
  location = inject(Location);
  activatedRoute = inject(ActivatedRoute);
  dialogService = inject(DialogService);
  conversationGear = inject(ConversationGear);

  openUser() {
    this.dialogService.open(UserDialog);
  }

  openMenuItem(dataMenuItem: DataMenuInterface) {
    if (dataMenuItem.dialog) {
      this.dialogService.open(dataMenuItem.dialog);
    } else if (dataMenuItem.routerId === 'chat') {
      const activeCharacterId = this.characterGear.currentCharacterId();
      const activeConversationId = this.conversationGear.activeConversationId();
      let chatRoute = [];
      if (activeCharacterId) {
        if (activeConversationId) {
          chatRoute = [dataMenuItem.linkTo, activeCharacterId], { queryParams: { c: this.conversationGear.activeConversationId() } };
        } else {
          chatRoute = [dataMenuItem.linkTo, activeCharacterId];
        }
      } else {
        chatRoute = [dataMenuItem.linkTo, ''];
      }
      console.log('go chat', chatRoute);
      this.router.navigate(chatRoute);
    } else {
      this.router.navigate([dataMenuItem.linkTo]);
    }

    const mobile = isMobile();
    if (mobile) {
      this.navbarState.toggleLeftBarsMenu(false);
    }
  }

}
