import { DOCUMENT } from '@angular/common';
import { Injectable, afterNextRender, inject } from '@angular/core';
import Plausible from 'plausible-tracker';
import { environment } from '../env';

@Injectable({
  providedIn: 'root',
})
export class PlausibleService {
  document = inject(DOCUMENT);
  // website = `https://c.losa.vn`;
  plausible = Plausible({
    trackLocalhost: true,
    domain: this.document.location.host, // losa.vn || awread.vn
    apiHost: 'https://p.awread.vn',
    // apiHost: 'https://${this.document.location.host}/plausible',
  });

  enableAutoPageviews = () => {};
  trackEvent = (eventName: any, options?: any, eventData?: any) => {};
  trackPageview = (eventData?: any, options?: any) => {};
  constructor() {}

  start() {
    if (environment.production) {
        console.info(
          'plausible started: ',
          this.document.location.protocol,
          this.document.location.host,
        );
        this.enableAutoPageviews = this.plausible.enableAutoPageviews;
        this.trackEvent = this.plausible.trackEvent;
        this.trackPageview = this.plausible.trackPageview;
        this.enableAutoPageviews();
    }
  }
}
