import { Injectable, signal } from '@angular/core';
import { AppStorageObject } from '@app/generated';
import { TreeNode } from 'configs/global';
import { nanoid } from 'nanoid';

export enum TreeNodeAction {
    ADD = 'add',
    DELETE = 'delete',
    UPDATE = 'update',
}

@Injectable({
    providedIn: 'root'
})
export class NodeManagerService {
    logs = signal<({ node: TreeNode, action: TreeNodeAction, timestamp: number })[]>([]);
    readonly ROOT_ID = "";
    nodeMap = signal<Map<string, TreeNode>>(new Map());
    childrenMap = signal<Map<string | null, string[]>>(new Map());
    rootNode = signal<TreeNode>(this.createRootNode('temp-id', 'temp-key'));
    activeNode = signal<TreeNode>(this.rootNode());

    clearLogs() {
        this.logs.set([]);
    }

    selectRootNode(): TreeNode {
        return this.nodeMap().get(this.ROOT_ID)!;
    }

    private createRootNode(projectId: string, rootKey: string): TreeNode {
        return {
            parentId: '',
            objectId: '',
            projectId: projectId,
            name: 'THIS IS ROOT',
            path: `/`,
            position: 0,
        };
    }

    addNode(node: TreeNode) {
        this.nodeMap.update(map => {
            const newMap = new Map(map);
            newMap.set(node.objectId, node);
            return newMap;
        });

        this.childrenMap.update(map => {
            const newMap = new Map(map);
            const actualParentId = node.parentId;
            if (!newMap.has(actualParentId)) {
                newMap.set(actualParentId, []);
            }
            newMap.get(actualParentId)!.push(node.objectId);
            return newMap;
        });

        this.logs.update(log => [...log, { node, action: TreeNodeAction.ADD, timestamp: Date.now() }]);

        this.recalculateNodePosition({ nodeId: node.objectId, newIndex: node.position });
    }

    updateNode(objectId: string, updates: Partial<TreeNode>) {
        this.updateNodeInMap(objectId, updates);
    }

    deleteNode(nodeToRemove: TreeNode) {
        const removingObjectId = nodeToRemove.objectId;
        if (removingObjectId === this.ROOT_ID) {
            console.warn("Cannot remove root node");
            return;
        }

        const children = this.childrenMap().get(removingObjectId);
        if (children) {
            children.forEach(childId => {
                const childNode = this.nodeMap().get(childId);
                if (childNode) {
                    this.deleteNode(childNode);
                }
            });
        }

        this.logs.update(log => {
            const affectedNodes = this.getAffectedNodes(removingObjectId);
            return [
                ...log,
                ...affectedNodes.map(affectedNode => ({ node: affectedNode, action: TreeNodeAction.DELETE, timestamp: Date.now() })),
            ];
        });

        this.nodeMap.update(currentMap => {
            const newMap = new Map(currentMap);
            newMap.delete(removingObjectId);
            return newMap;
        });

        this.childrenMap.update(currentMap => {
            const newMap = new Map(currentMap);
            const parentId = nodeToRemove.parentId || this.ROOT_ID;
            const parentChildren = newMap.get(parentId);
            if (parentChildren) {
                const index = parentChildren.indexOf(removingObjectId);
                if (index !== -1) {
                    parentChildren.splice(index, 1);
                    if (parentChildren.length === 0) {
                        newMap.delete(parentId);
                    } else {
                        newMap.set(parentId, parentChildren);
                    }
                }
            }
            return newMap;
        });

        // Instead of recalculateNodePositionByParentId, use updateChildPositions
        const parentId = nodeToRemove.parentId || this.ROOT_ID;
        const updatedChildIds = (this.childrenMap().get(parentId) || []).filter(id => id !== removingObjectId);
        this.updateChildPositions(parentId, updatedChildIds);
    }

    private updateNodeAndDescendants(node: TreeNode, newParentId: string, newPath: string, updateChildrenMap: boolean = false): void {
        const updatedNode: TreeNode = {
            ...node,
            parentId: newParentId,
            path: newPath,
        };

        this.updateNode(node.objectId, updatedNode);

        const childNodes = this.getChildNodes(node.objectId);
        childNodes.forEach(childNode => {
            const childNewPath = `${newPath}/${childNode.objectId}`;
            this.updateNodeAndDescendants(childNode, node.objectId, childNewPath);
        });

        if (updateChildrenMap) {
            this.childrenMap.set(this.updateChildrenMap(this.nodeMap()));
        }
    }

    private getAffectedNodes(nodeId: string): TreeNode[] {
        const node = this.nodeMap().get(nodeId);
        if (!node) return [];

        const affectedNodes: TreeNode[] = [node];
        const childNodes = this.getChildNodes(nodeId);
        affectedNodes.push(...childNodes);

        return affectedNodes;
    }

    relocateNode(node: TreeNode, targetNode: TreeNode, position: 'before' | 'inside' | 'remove') {
        switch (position) {
            case 'remove':
                this.deleteNode(node);
                break;
        
            default:
                let parentNode: TreeNode = position === 'inside' ? targetNode : this.nodeMap().get(targetNode.parentId)!;
                
                if (!parentNode) {
                    console.error(`Parent node not found for relocation`);
                }
                
                // Calculate the new path for the relocated node
                let newPath = this.calculateNewPath(parentNode, node);

                // Update the node and its descendants with the new parent and path
                this.updateNodeAndDescendants(node, parentNode.objectId, newPath, true);

                // Determine the new index for the node in its new parent's children list
                const newIndex = this.calculateNewIndex(parentNode, targetNode, position);
                console.log('relocating for position', position, 'before', node.position, 'after', newIndex);
                // Recalculate positions of affected nodes
                this.recalculateNodePosition({ nodeId: node.objectId, newIndex });
                break;
        }
    }

    private calculateNewPath(parentNode: TreeNode, node: TreeNode): string {
        return parentNode.path === '/' ? `/${node.objectId}` : `${parentNode.path}/${node.objectId}`;
    }

    private calculateNewIndex(parentNode: TreeNode, targetNode: TreeNode, position: 'before' | 'inside'): number {
        const childIds = this.childrenMap().get(parentNode.objectId) || [];
        if (position === 'inside') {
            return childIds.length;
        } else { // 'before'
            const targetIndex = childIds.indexOf(targetNode.objectId);
            return targetIndex !== -1 ? targetIndex : childIds.length;
        }
    }

    private recalculateNodePosition({ nodeId, newIndex }: { nodeId: string, newIndex: number }): void {
        const node = this.nodeMap().get(nodeId);
        if (!node) {
            console.warn(`Node with id ${nodeId} not found`);
            return;
        }

        const parentId = node.parentId;
        const childIds = this.childrenMap().get(parentId) || [];

        // Create a deep copy of the childIds array for logging
        const childIdsBefore = [...childIds];
        console.log('Before recalculation:', { nodeId, newIndex, childIds: childIdsBefore });

        // Remove the node from its current position
        const oldIndex = childIds.indexOf(nodeId);
        if (oldIndex !== -1) {
            childIds.splice(oldIndex, 1);
        }

        // Insert the node at its new position
        if (newIndex !== undefined) {
            childIds.splice(newIndex, 0, nodeId);
        } else {
            // If no new index is provided, add the node to the end
            childIds.push(nodeId);
        }

        console.log('After recalculation:', { nodeId, newIndex, childIds: [...childIds] });

        // Update the positions of all children
        this.updateChildPositions(parentId, childIds);
    }

    private updateChildPositions(parentId: string, childIds: string[]): void {
        childIds.forEach((childId, index) => {
            this.updateNode(childId, { position: index });
        });

        this.childrenMap.update(currentMap => {
            const newMap = new Map(currentMap);
            newMap.set(parentId, childIds);
            return newMap;
        });

        this.logs.update(logs => {
            const affectedNodes = childIds.flatMap(childId =>
                this.getAffectedNodes(childId).map(affectedNode => ({
                    node: affectedNode,
                    action: TreeNodeAction.UPDATE,
                    timestamp: Date.now()
                }))
            );
            return [...logs, ...affectedNodes];
        });
    }

    private updateChildrenMap(nodeMap: Map<string, TreeNode>): Map<string | null, string[]> {
        const newChildrenMap = new Map<string | null, string[]>();

        nodeMap.forEach(node => {
            if (node.objectId === this.ROOT_ID) return;

            const parentId = node.parentId;
            if (!newChildrenMap.has(parentId)) {
                newChildrenMap.set(parentId, []);
            }
            newChildrenMap.get(parentId)!.push(node.objectId);
        });

        return newChildrenMap;
    }


    private updateNodeInMap(objectId: string, updates: Partial<TreeNode>) {
        this.nodeMap.update(currentMap => {
            const newMap = new Map(currentMap);
            const existingNode = newMap.get(objectId);
            if (existingNode) {
                const updatedNode = { ...existingNode, ...updates };
                newMap.set(objectId, updatedNode);
            } else {
                console.warn(`Node ${objectId} not found in the node map`);
            }
            return newMap;
        });
    }


    dataToTreeNodes(projectId: string, storageObjects: AppStorageObject[]): TreeNode[] {
        const sameProject = this.rootNode().projectId === projectId;
        const rootNode = this.createRootNode(projectId, this.ROOT_ID);
        this.activeNode.set(rootNode);
        this.rootNode.set(rootNode);
        const newNodeMap = this.createNodeMap(rootNode, storageObjects);
        const newChildrenMap = this.updateChildrenMap(newNodeMap);
        this.nodeMap.set(newNodeMap);
        this.childrenMap.set(newChildrenMap);

        return Array.from(newNodeMap.values());
    }

    private createNodeMap(rootNode: TreeNode, storageObjects: AppStorageObject[], reuseState = false): Map<string, TreeNode> {
        const newNodeMap = new Map<string, TreeNode>();
        newNodeMap.set(rootNode.objectId, rootNode);
        storageObjects.forEach(obj => {
            newNodeMap.set(obj.objectId, reuseState ? {
                ...(this.nodeMap().get(obj.objectId) ?? {}),
                ...obj
            } : obj);
        });

        return newNodeMap;
    }

    createNode({ location, userId, projectId, targetNode, objectId, name }: { location: 'up' | 'down' | 'in', userId: string, projectId: string, targetNode: TreeNode, objectId?: string, name?: string }): TreeNode {
        objectId = objectId ?? nanoid();

        if (!userId || !projectId) {
            throw new Error('User ID or Space ID is missing');
        }
        const timestamp = new Date().toISOString();
        const childrenLength = this.getChildNodes(targetNode.objectId).length;
        let path = ''; 
        let position = 0; 
        let parentId = this.ROOT_ID;
        const isRoot = targetNode.parentId === this.ROOT_ID;
        switch (location) {
            case 'up':
                path = targetNode.path.split('/').slice(0, -1).join('/') + `/${objectId}`;
                position = targetNode.position === 0 ? 0 : targetNode.position;
                parentId = targetNode.parentId;
                break;
            case 'down':
                path = targetNode.path.split('/').slice(0, -1).join('/') + `/${objectId}`;
                position = isRoot ? childrenLength : targetNode.position + 1;
                parentId = targetNode.parentId;
                break;
            case 'in':
                path = isRoot ? `/${objectId}` : `${targetNode.path}/${objectId}`;
                position = childrenLength;
                parentId = targetNode.objectId;
                break;
        }
        console.log('adding ', location, 'path', path, 'position', position);

        const nodeStorageObject: AppStorageObject = {
            id: 'temp-id',
            name: name ?? '',
            objectId,
            userId: userId,
            parentId,
            path: path,
            position: position,
            size: 0,
            projectId,
            version: 'default',
            metadata: {},
            createdAt: timestamp,
            updatedAt: timestamp,
            contentType: 'application/json',
        };
        return nodeStorageObject;
    }

    

    getChildNodes(nodeId: string): TreeNode[] {
        let childIds = this.childrenMap().get(nodeId) || [];
        return childIds
            .map(id => this.nodeMap().get(id))
            .filter(item => !!item)
            .sort((a, b) => a!.position - b!.position) as TreeNode[];
    }


    // Method to get a specific node
    getNode(nodeId: string): TreeNode | undefined {
        return this.nodeMap().get(nodeId);
    }

    // Method to check if a node exists
    nodeExists(nodeId: string): boolean {
        return this.nodeMap().has(nodeId);
    }

}