import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'dialog-welcome-trial',
    template: `
    <div class="prose py-6 px-4">
        <h3> Chào mừng bạn đến với LOSA AI! </h3>
        <p> Chào mừng bạn đến với ứng dụng Losa AI – trợ lý ảo thông minh!
Losa AI sẵn sàng đồng hành cùng bạn, giúp bạn giải quyết công việc một cách hiệu quả và tiện lợi. Hãy trải nghiệm những tính năng vượt trội, từ hỗ trợ quản lý công việc, tư vấn thông tin, đến giải trí thư giãn.
Cùng khám phá Losa AI ngay hôm nay!</p>
        <!-- <p>Bạn đã được TẶNG gói dùng thử trị giá 50.000 VND có hiệu lực trong <strong>7 ngày</strong> </p> -->
        <!-- <p>Sau khi hết hạn dùng thử, bạn có thể lựa chọn tiếp tục dùng miễn phí hoặc nâng cấp gói sau. <p> -->
    </div>
    `,
})

export class WelcomeTrialDialog {
}