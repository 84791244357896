{
  "name": "app",
  "version": "1.0.0",
  "license": "MIT",
  "scripts": {
    "test:blog": "pnpm tsx ./configs/src/pages/page-markdown-parser.ts",
    "build:blog": "pnpm tsx ./configs/src/pages/build-page.ts contents contents/blog build/contents",
    "build:model": "pnpm tsx ./configs/src/models/build-model.ts",
    "build:model:prod": "pnpm tsx ./configs/src/models/build-model.ts production",
    "read:character": "pnpm tsx ./configs/src/characters/read-character.ts",
    "build:character": "pnpm read:character &&pnpm tsx ./configs/src/models/build-character.ts",
    "build:character:prod": "pnpm read:character &&pnpm tsx ./configs/src/models/build-character.ts production",
    "build:prompt": "pnpm tsx ./configs/src/prompts/build-prompt.ts",
    "build:menu": "pnpm tsx ./configs/src/menu/build-menu.ts",
    "build:skill": "pnpm tsx ./configs/src/menu/build-skill.ts",
    "ng": "nx",
    "g": "nx g",
    "bg": "set DEBUG=TRUE &&pnpm nx serve backend-graphql",
    "bg-prod": "set NODE_ENV=production&&pnpm nx serve backend-graphql",
    "chatter": "set ENVIRONMENT=local && SET NODE_OPTIONS=--max_old_space_size=4096&&nx run chatter-web:serve:development --host=0.0.0.0 --port=5000 --disableHostCheck=true --o=true --hmr true",
    "build:chatter": "nx build chatter-web",
    "build-image:chatter": "docker build --progress=plain -f configs/common/devops/dockers/losa-chatter.Dockerfile -t registry.awread.vn/losa-chatter:latest . ",
    "push-image:chatter": "docker push registry.awread.vn/losa-chatter:latest",
    "build-push:chatter": "pnpm build:chatter --skip-nx-cache &&pnpm build-image:chatter && pnpm push-image:chatter",
    "build:graphql": "nx build backend-graphql --skip-cache",
    "build-image:graphql": "docker build  --progress=plain -f configs/common/devops/dockers/losa-graphql.Dockerfile -t registry.awread.vn/losa-graphql:latest . ",
    "push-image:graphql": "docker push registry.awread.vn/losa-graphql:latest",
    "build-push:graphql": "pnpm build:graphql --skip-nx-cache &&pnpm build-image:graphql && pnpm push-image:graphql",
    "serve:ssr": "set ENVIRONMENT=local && node build/apps/frontend-chatter/server/server.mjs",
    "build:ssr": "set ENVIRONMENT=local && pnpm build:chatter --skip-nx-cache && pnpm serve:ssr",
    "buyer": "nx serve buyer-web --port=5200 -o --host 0.0.0.0",
    "start": "nx serve",
    "build": "nx build",
    "watch-codegen": "graphql-codegen --watch --verbose",
    "test": "vitest",
    "after-build": "node ./after-build.js",
    "before-build": "node ./before-build.js"
  },
  "private": true,
  "type": "module",
  "engines": {
    "node": "22.x",
    "npm": "10.x"
  },
  "dependencies": {
    "@angular/common": "18.2.11",
    "@angular/compiler": "18.2.11",
    "@angular/core": "18.2.11",
    "@angular/forms": "18.2.11",
    "@angular/platform-browser": "18.2.11",
    "@angular/platform-browser-dynamic": "18.2.11",
    "@angular/platform-server": "18.2.11",
    "@angular/router": "18.2.11",
    "@angular/service-worker": "18.2.11",
    "@angular/ssr": "18.2.11",
    "@anthropic-ai/sdk": "^0.32.1",
    "@aws-sdk/client-s3": "3.688.0",
    "@aws-sdk/s3-request-presigner": "^3.688.0",
    "@dataplan/json": "0.0.1-beta.25",
    "@dataplan/pg": "0.0.1-beta.27",
    "@dicebear/collection": "^9.2.2",
    "@dicebear/core": "^9.2.2",
    "@floating-ui/dom": "^1.6.12",
    "@grafserv/persisted": "0.0.0-beta.23",
    "@graphile-contrib/pg-many-to-many": "2.0.0-beta.5",
    "@graphile/pg-aggregates": "0.2.0-beta.7",
    "@graphile/simplify-inflection": "8.0.0-beta.5",
    "@graphql-codegen/client-preset": "^4.5.0",
    "@huggingface/inference": "^2.8.1",
    "@langchain/community": "^0.3.12",
    "@langchain/core": "^0.3.17",
    "@langchain/google-genai": "^0.1.3",
    "@langchain/openai": "^0.3.12",
    "@mdit/plugin-align": "^0.13.1",
    "@ng-select/ng-select": "^13.9.1",
    "@ngneat/dialog": "^5.1.1",
    "@ngneat/until-destroy": "^10.0.0",
    "@nx/angular": "20.0.12",
    "@nx/workspace": "20.0.12",
    "@qdrant/js-client-rest": "^1.12.0",
    "@tailwindcss/typography": "^0.5.15",
    "@tanstack/angular-query-devtools-experimental": "^5.60.0",
    "@tanstack/angular-query-experimental": "^5.60.0",
    "@tinymce/tinymce-angular": "^8.0.1",
    "@tiptap/core": "^2.9.1",
    "@tiptap/extension-bubble-menu": "^2.9.1",
    "@tiptap/extension-color": "^2.9.1",
    "@tiptap/extension-floating-menu": "^2.9.1",
    "@tiptap/extension-highlight": "^2.9.1",
    "@tiptap/extension-horizontal-rule": "^2.9.1",
    "@tiptap/extension-image": "^2.9.1",
    "@tiptap/extension-link": "^2.9.1",
    "@tiptap/extension-mention": "^2.9.1",
    "@tiptap/extension-placeholder": "2.9.1",
    "@tiptap/extension-table": "^2.9.1",
    "@tiptap/extension-table-cell": "^2.9.1",
    "@tiptap/extension-table-header": "^2.9.1",
    "@tiptap/extension-table-row": "^2.9.1",
    "@tiptap/extension-task-item": "^2.9.1",
    "@tiptap/extension-task-list": "^2.9.1",
    "@tiptap/extension-text-align": "^2.9.1",
    "@tiptap/extension-text-style": "^2.9.1",
    "@tiptap/extension-underline": "^2.9.1",
    "@tiptap/html": "^2.9.1",
    "@tiptap/pm": "^2.9.1",
    "@tiptap/starter-kit": "^2.9.1",
    "@tiptap/suggestion": "^2.9.1",
    "@types/hast": "^3.0.4",
    "@types/mdast": "^4.0.4",
    "@xenova/transformers": "^2.17.2",
    "autoprefixer": "^10.4.20",
    "axios": "1.7.7",
    "body-parser": "^1.20.3",
    "browser-fs-access": "^0.35.0",
    "cheerio": "1.0.0",
    "cohere-ai": "^7.14.0",
    "commitlint": "^19.5.0",
    "connect-pg-simple": "^10.0.0",
    "connect-redis": "^7.1.1",
    "cors": "^2.8.5",
    "crc": "^4.3.2",
    "cron": "^3.1.9",
    "daisyui": "^4.12.14",
    "date-fns": "^4.1.0",
    "dayjs": "^1.11.13",
    "dotenv": "^16.4.5",
    "eruda": "^3.4.1",
    "express": "^4.21.1",
    "express-jwt": "^8.4.1",
    "express-session": "^1.18.1",
    "fast-xml-parser": "^4.5.0",
    "flatpickr": "^4.6.13",
    "google-auth-library": "^9.14.2",
    "grafast": "0.1.1-beta.16",
    "grafserv": "0.1.1-beta.13",
    "graphile-utils": "5.0.0-beta.25",
    "graphile-worker": "^0.16.6",
    "graphql": "16.9.0",
    "graphql-request": "^7.1.2",
    "graphql-ws": "5.16.0",
    "html-to-text": "^9.0.5",
    "http-proxy-middleware": "^3.0.3",
    "jose": "^5.9.6",
    "jsonwebtoken": "^9.0.2",
    "jwks-rsa": "^3.1.0",
    "katex": "^0.16.11",
    "langchain": "^0.3.5",
    "llama3-tokenizer-js": "^1.2.0",
    "localstorage-polyfill": "^1.0.1",
    "lucide-angular": "^0.456.0",
    "markdown-it": "^14.1.0",
    "mjml": "^4.15.3",
    "nanoid": "^5.0.8",
    "ngx-image-cropper": "^8.1.0",
    "ngx-tiptap": "^11.1.0",
    "node-html-parser": "^6.1.13",
    "nodemailer": "^6.9.16",
    "openai": "^4.71.1",
    "passport": "^0.7.0",
    "passport-google-oauth20": "^2.0.0",
    "passport-jwt": "^4.0.1",
    "passport-local": "^1.0.0",
    "pg": "^8.13.1",
    "pg-listen": "^1.7.0",
    "plausible-tracker": "^0.3.9",
    "postgraphile": "5.0.0-beta.33",
    "postgraphile-plugin-connection-filter": "3.0.0-beta.5",
    "qrcode": "^1.5.4",
    "redis": "^4.7.0",
    "reflect-metadata": "^0.2.2",
    "rehype-katex": "^7.0.1",
    "rehype-mathjax": "^6.0.0",
    "rehype-minify-whitespace": "^6.0.2",
    "rehype-parse": "^9.0.1",
    "rehype-remark": "^10.0.0",
    "rehype-stringify": "^10.0.1",
    "remark": "^15.0.1",
    "remark-frontmatter": "^5.0.0",
    "remark-gfm": "^4.0.0",
    "remark-math": "^6.0.0",
    "remark-mdx": "^3.1.0",
    "remark-parse": "^11.0.0",
    "remark-rehype": "^11.1.1",
    "remark-stringify": "^11.0.0",
    "remark-toc": "^9.0.0",
    "replace-in-file": "^8.2.0",
    "rxjs": "7.8.1",
    "sbd": "^1.0.19",
    "sentence-splitter": "^5.0.0",
    "sharp": "^0.33.5",
    "slugify": "^1.6.6",
    "source-map-support": "^0.5.21",
    "tailwindcss": "^3.4.14",
    "tailwindcss-animated": "^1.1.2",
    "tesseract.js": "^5.1.1",
    "tinymce": "^7.5.0",
    "tippy.js": "^6.3.7",
    "tiptap-markdown": "^0.8.10",
    "tslib": "^2.8.1",
    "unified": "^11.0.5",
    "unist-util-visit": "^5.0.0",
    "vietnam-qr-pay": "^1.4.0",
    "vite-tsconfig-paths": "^5.1.2",
    "xcase": "^2.0.1",
    "yaml": "^2.6.0",
    "zod": "^3.23.8",
    "zone.js": "0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/architect": "0.1802.11",
    "@angular-devkit/build-angular": "18.2.11",
    "@angular-devkit/core": "18.2.11",
    "@angular-devkit/schematics": "18.2.11",
    "@angular-eslint/eslint-plugin": "18.4.0",
    "@angular-eslint/eslint-plugin-template": "18.4.0",
    "@angular-eslint/template-parser": "18.4.0",
    "@angular/cli": "~18.2.11",
    "@angular/compiler-cli": "18.2.11",
    "@angular/language-service": "18.2.11",
    "@angular/pwa": "18.2.11",
    "@commitlint/cli": "^19.5.0",
    "@commitlint/config-angular": "^19.5.0",
    "@commitlint/config-conventional": "^19.5.0",
    "@graphql-codegen/cli": "^5.0.3",
    "@graphql-codegen/typed-document-node": "^5.0.11",
    "@graphql-codegen/typescript": "^4.1.1",
    "@graphql-codegen/typescript-operations": "^4.3.1",
    "@graphql-typed-document-node/core": "^3.2.0",
    "@nx/devkit": "20.0.12",
    "@nx/esbuild": "20.1.2",
    "@nx/express": "20.0.12",
    "@nx/js": "20.0.12",
    "@nx/nest": "20.0.12",
    "@nx/node": "20.0.12",
    "@nx/plugin": "20.0.12",
    "@parcel/watcher": "^2.5.0",
    "@phenomnomnominal/tsquery": "^6.1.3",
    "@schematics/angular": "18.2.11",
    "@swc-node/register": "1.10.9",
    "@swc/core": "1.9.2",
    "@swc/helpers": "0.5.15",
    "@types/body-parser": "^1.19.5",
    "@types/connect-pg-simple": "^7.0.3",
    "@types/connect-redis": "^0.0.23",
    "@types/cors": "^2.8.17",
    "@types/express": "5.0.0",
    "@types/express-session": "^1.18.0",
    "@types/html-to-text": "^9.0.4",
    "@types/jsonwebtoken": "^9.0.7",
    "@types/markdown-it": "^14.1.2",
    "@types/node": "^22.9.0",
    "@types/nodemailer": "^6.4.16",
    "@types/passport": "^1.0.17",
    "@types/passport-local": "^1.0.38",
    "@types/pg": "^8.11.10",
    "@types/sbd": "^1.0.5",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "~8.13.0",
    "@typescript-eslint/parser": "~8.13.0",
    "@vitest/ui": "^2.1.4",
    "esbuild": "^0.19.12",
    "eslint": "~9.14.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-require-extensions": "^0.1.3",
    "graphql-codegen-persisted-query-ids": "^0.2.0",
    "husky": "^9.1.6",
    "jsonc-eslint-parser": "^2.4.0",
    "lint-staged": "^15.2.10",
    "node-mocks-http": "^1.16.1",
    "nx": "20.0.12",
    "postcss": "^8.4.48",
    "prettier": "3.3.3",
    "ts-node": "10.9.2",
    "tsx": "^4.19.2",
    "typescript": "5.5.4",
    "utility-types": "^3.11.0",
    "vite": "^5.4.11",
    "vite-node": "^2.1.4",
    "vitest": "^2.1.4"
  },
  "lint-staged": {
    "apps/**/*.{js,ts,scss}": [
      "prettier --write"
    ],
    "libs/**/*.{js,ts,scss}": [
      "prettier --write"
    ]
  },
  "packageManager": "pnpm@8.14.1+sha1.d039b38e0b20ad012ed548e44267b8d4c88b447a"
}
